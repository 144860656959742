import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { gsap } from "../plugins/gsap"
import { ScrollTrigger } from "../plugins/gsap/ScrollTrigger"

import Layout from "../components/layout"
import Seo from "../components/seo"
import GradientGhostButton from "../components/ui/pink-gradient-ghost-button"
import { Container } from "../components/ui/grid"
import Customers from "../components/customers";
import { GatsbyImage } from "gatsby-plugin-image"
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Recognition } from "../components/ui/recognition"
import { FooterReadyFor } from "../components/ui/footer-ready-for"
import Feature from "../components/ui/product-feature"
import PinkGradientGhostButton from "../components/ui/pink-gradient-ghost-button"
import { Testimonials } from "../components/ui/testimonials"

gsap.registerPlugin(ScrollTrigger);

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_node, children) => {
      return <h1 className="xl:text-5xl m-0 header-title">{children}</h1>;
    },
    [BLOCKS.HEADING_2]: (_node, children) => {
      return <h2 className="xl:text-3xl mb-2">{children}</h2>;
    },
    [BLOCKS.PARAGRAPH]: (_node, children) => {
      return <p className="xl:text-xl m-0">{children}</p>;
    },
  },
};

const MobileTesting = ({ location }) => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulPageMobileTesting(contentful_id: { eq: "2sxFmYLTCztiRBiTTaXptO" } ) {
          title
          description
          featuredImage {
            gatsbyImageData
            resize(width: 512, quality: 70) {
              src
            }
          }
          heroImage {
            gatsbyImageData(
              placeholder: NONE
            )
            title
          }
          heroContent {
            raw
          }
          heroButtons {
            title
            url
          }
          firstSectionTitle
          firstSectionSubTitle
          firstSectionText {
            raw
          }
          firstSectionImage {
            gatsbyImageData(
              placeholder: NONE
            )
            title
          }
          featuresSectionPreTitle 
          featuresSectionTitle
          features {
            title
            id
            content {
              raw
            }
            image {
              gatsbyImageData(
                placeholder: NONE
                quality: 100
              )
              title
            }
            button {
              title
              url
            }
          }
          secondSectionTitle
          secondSectionText {
            raw
          }
          secondSectionImage {
            gatsbyImageData(
              placeholder: BLURRED
            )
            title
          }
          iconSectionPreTitle 
          iconSectionTitle
          iconSectionIcons {
            title
            text
            icon {
              gatsbyImageData(
                placeholder: BLURRED
              )
              title
            }
          }
          recognition {
            title
            text
            badges {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 100
              )
            }
          }
          footer {
            title
            buttons {
                title
                url

            }
          }
        }
      }
    `
  );

  const title = data?.title || "";
  const description = data?.description || "";
  const thumbnail = data?.featuredImage?.resize?.src || "";
  const heroImage = data?.heroImage;
  const heroContent = data?.heroContent;
  const heroButtons = data?.heroButtons;
  const firstSectionTitle = data?.firstSectionTitle || "";
  const firstSectionSubTitle = data?.firstSectionSubTitle || "";
  const firstSectionImage = data?.firstSectionImage;
  const firstSectionText = data?.firstSectionText;
  const featuresSectionPreTitle = data?.featuresSectionPreTitle || "";
  const featuresSectionTitle = data?.featuresSectionTitle || "";
  const features = data?.features || [];
  const secondSectionTitle = data?.secondSectionTitle || "";
  const secondSectionImage = data?.secondSectionImage;
  const secondSectionText = data?.secondSectionText;
  const IconSectionPreTitle = data?.iconSectionPreTitle;
  const IconSectionTitle = data?.iconSectionTitle;
  const IconSectionIcons = data?.iconSectionIcons;
  const recognition = data?.recognition || {};
  const footer = data?.footer || {};


  return (
    <Layout
      location={location}
    >
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap" rel="stylesheet" />

      <Seo
        title={title}
        description={description}
        image={thumbnail}
        url={location.href}
      />

      <div className='mobile-testing-wrapper'>

        <header>
          <Container className='col-2'>
            <div className="text-and-btns-section">
              {heroContent && renderRichText(heroContent, options)}

              <div className="btn-section lg-btn">
                {heroButtons.map(({ title, url }, i) => (
                  <GradientGhostButton title={title} href={url} key={i}/>
                ))}
              </div>
            </div>
          <div className='main-img'>
            <GatsbyImage
              image={heroImage?.gatsbyImageData}
              alt={heroImage?.title}
            />
          </div>
          </Container>
          <div className="pt-12">
            <p className="mb-10 mt-5 text-2xl text-center text-gray-light font-normal opacity-75">
              Validating Every Code Change At:
            </p>
            <div className="pb-10">
              <Customers all/>
            </div>
          </div>
        </header>
          <Testimonials />

        <Container className='col-3-to-2 my-4'>
          <div>
            <h1 className="xl:text-5xl m-0 header-title">{firstSectionTitle}</h1>
            <h2 className="font-normal xl:text-3xl mb-2">{firstSectionSubTitle}</h2>
          </div>
          <div>
            {firstSectionText && renderRichText(firstSectionText, options)}
          </div>
        </Container>
        <div className='pink-gradiant' >
          <Container>
            <GatsbyImage
              image={firstSectionImage?.gatsbyImageData}
              alt={firstSectionImage?.title}
            />
          </Container>
        </div>

        <div className='new-pages-wrapper'>
        <div className='bg-indigo-dark text-white'>
          <div className="section-title" style={{ padding: '80px 24px 24px'}}>
            <h3 className="xl:text-l m-8">{featuresSectionPreTitle}</h3>
            <h1 style={{ fontWeight: 'bold' }} className="xl:text-5xl">{featuresSectionTitle}</h1>
          </div>
          <div className='features-container top'>
            {features.map((item, i) =>
              <Feature
                key={i}
                img={item?.image}
                content={item?.content}
                button={item?.button && <PinkGradientGhostButton title={item.button.title} href={item.button.url}/>}
              />
            )}
          </div>
        </div>
        </div>

        <Container>
          <div className="section-title">
            <h3 className="xl:text-l">{IconSectionPreTitle}</h3>
            <h1 className="xl:text-5xl">{IconSectionTitle}</h1>
          </div>
          <div className="icons-container">
            {IconSectionIcons.map((icon) => (
              <IconAndText
                title={icon.title}
                text={icon.text}
                image={icon.icon.gatsbyImageData}
                key={icon.title}
              />
            ))}
          </div>
        </Container>

        <Container className='col-2-to-3'>
          <div className="text-section">
            <h1 className="xl:text-6xl">{secondSectionTitle}</h1>
            {secondSectionText && renderRichText(secondSectionText, options)}
          </div>

          <GatsbyImage
            image={secondSectionImage?.gatsbyImageData}
            alt={secondSectionImage?.title}
          />
        </Container>

      <Recognition
          recognition={recognition}
        />
        <FooterReadyFor
          title={footer?.title || ''}
          buttons={footer?.buttons || []} 
        />
      </div>
    </Layout>
  )
}

const IconAndText = ({ title, text, image }) => (
  <div className="icon">
    <GatsbyImage
      image={image}
      alt={title}
    />
    <h3>{title}</h3>
    <p>{text}</p>
  </div>
);

export default MobileTesting
