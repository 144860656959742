import React from "react";

export const SlideNavigation  = (props) => {
  const {
    index,
    setIndex,
    length
  } = props;

  return <div className='flex gap-2 justify-center'>
    {[...Array(length).keys()].map(i => <div 
        onClick={() => setIndex(i)}
        role='button' 
        onKeyDown={() => {}}
        tabIndex={0}
      >
        <Slide key={i} isSelected={i === index}/>
      </div>
    )}
  </div>
}

const Slide = ({ isSelected = false }) => {
  return <>
    <svg fill="#000000" width="16px" height="16px" viewBox="0 0 36 36" version="1.1">
        <title fill="#fff" >dot-circle-line</title>     
        <path d="M18,11a7,7,0,1,1-7,7,7,7,0,0,1,7-7" class="clr-i-outline clr-i-outline-path-1" fill={isSelected ? "white" : "#bdb7b7"} ></path>
        <path d="M18,34A16,16,0,1,1,34,18,16,16,0,0,1,18,34ZM18,4A14,14,0,1,0,32,18,14,14,0,0,0,18,4Z" class="clr-i-outline clr-i-outline-path-2" fill={isSelected ? "white" : "#bdb7b7"} ></path>
        <rect x="0" y="0" width="36" height="36" fill-opacity="0" fill="#fff" ></rect>
    </svg>
  </>
}