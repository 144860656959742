import React from 'react';

export const PrevArrow = ({ onClick, className, color }) => {
  return <div
  onClick={onClick}
  role='button'
  className='arrow-button hidden sm:flex'
  onKeyDown={() => {}}
  tabIndex={0}
  >
  <svg 
    width="100px"
    height="100px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={className}
      d="M15 7L10 12L15 17"
      stroke={color || "#FFFFFF"}
      stroke-width="1.5"
      stroke-linecap="round" 
      stroke-linejoin="round"
    />
  </svg>
  </div>
}
