import React from 'react';

export const NextArrow = ({ onClick, className, color }) => {
  return <div
    onClick={onClick}
    role='button'
    onKeyDown={() => {}}
    className='arrow-button hidden sm:flex'
    tabIndex={0}
  >
    <svg 
      width="100px" 
      height="100px" 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        className={className} 
        d="M10 7L15 12L10 17" 
        stroke={color || "#FFFFFF"}
        stroke-width="1.5" 
        stroke-linecap="round" 
        stroke-linejoin="round"
      />
    </svg>
  </div>
}
