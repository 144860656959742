import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { NextArrow } from "../next-arrow";
import { PrevArrow } from "../prev-arrow";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import { SlideNavigation } from "./slide-navigation";

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p className="text-center text-lg sm:text-2xl text-white mx-8 sm:m-0 max-w-4xl">{children}</p>
    },
  },
}

export const Testimonials = () => {
  const { data } = useStaticQuery(
    graphql`
       query {
         data: contentfulList(contentful_id: { eq: "1lmiKiyjZmlztVlu9vKcbF" } ) {
          galleryList {
              title
              text
              description {
                raw
              }
              icon {
                gatsbyImageData(
                  placeholder: NONE
                )
              }
              image {
                gatsbyImageData(
                  placeholder: NONE
                )
              }
          }
        }
      }
    `
  )

  const [index, setIndex] = React.useState(0);
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);
  const testimonialRef = React.useRef(null);

  function handleTouchStart(e) {
      setTouchStart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e) {
      setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchEnd() {
      if (touchStart - touchEnd > 150) {
          handlePrev();
      }

      if (touchStart - touchEnd < -150) {
          handleNext();
      }
  }

  const handleNext = () => {
    setIndexSmoothly((index + 1) % data.galleryList.length);
  }

  const handlePrev = () => {
    setIndexSmoothly((index - 1 + data.galleryList.length) % data.galleryList.length);
  }

  const setIndexSmoothly = (index) => {
      if (testimonialRef.current) {
        testimonialRef.current.classList.add('fade-out');
        setTimeout(() => {
          setIndex(index)
          testimonialRef.current.classList.remove('fade-out');
        }, 400);
      }
  }

  const testimonialsDetails = data.galleryList || [];
  return <div className="sm:flex sm:justify-center sm:items-center sm:gap-4 bg-indigo-royal py-16"
    onTouchStart={handleTouchStart}
    onTouchMove={handleTouchMove}
    onTouchEnd={handleTouchEnd}
  >
    <PrevArrow onClick={handlePrev} className="arrow" />
    <div ref={testimonialRef} className='testimonial'>
      <div className='sm:h-44 flex items-center'>
        {renderRichText(testimonialsDetails[index]?.description, options)}
      </div>
      <div className='grid sm:flex sm:justify-center sm:items-center mt-12 gap-8'>
        <GatsbyImage
          className='mx-auto mb-4 sm:m-0 gradient-background'
          imgStyle={{ margin: 4, borderRadius: '50%', height: 'auto', width: 'auto' }}
          image={testimonialsDetails[index]?.image?.gatsbyImageData}
        />
        <div className='sm:flex sm:items-center sm:justify-items-start sm:w-112'>
          <div className='grid sm:block text-center sm:text-left justify-items-center sm:justify-items-start'>
            <h3 className="text-white text-2xl font-bold m-0">{testimonialsDetails[index]?.title}</h3>
            <div className="text-white text-xl font-light mb-4">{testimonialsDetails[index]?.text}</div>
            <div className="w-52">
              <GatsbyImage
                image={testimonialsDetails[index]?.icon?.gatsbyImageData}
              />
            </div>
          </div>
        </div>
        <div className='sm:hidden'>
          <SlideNavigation
            index={index}
            setIndex={setIndexSmoothly}
            length={testimonialsDetails.length}
          />
        </div>
      </div>
    </div>
    <NextArrow onClick={handleNext} className="arrow hidden sm:flex" />
</div>
};
